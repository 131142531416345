// src/components/ManageDonations.js

import React, { useState, useEffect, useCallback } from 'react';
import { FaSearch, FaFilter, FaTimes, FaCalendarAlt, FaClock, FaQuoteRight, FaCheckCircle, FaCalendarCheck, FaTruck, FaEdit, FaFileAlt, FaDollarSign, FaBuilding, FaWarehouse, FaUser, FaBox, FaMoneyBillWave, FaShippingFast } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/ManageDonations.css';
import Sidebar from './Sidebar';
import Header from './Header';
import Loader from './Loader';
import ErrorMessage from './ErrorMessage';
import AssignDonation from './AssignDonation';
import { donationService } from '../services/donationService';
import { companyService } from '../services/companyService';
import { distributionCenterService } from '../services/distributionCenterService';
import { userService } from '../services/userService';
import { authService } from '../services/authService';
import { purchaseService } from '../services/purchaseService';
import { messageService } from '../services/messageService';

const statusOptions = [
  { value: 'Pending', icon: FaClock, color: '#FFA500' },
  { value: 'Valued', icon: FaMoneyBillWave, color: '#20B2AA' },
  { value: 'Quoted', icon: FaQuoteRight, color: '#4299E1' },
  { value: 'PO Sent', icon: FaCheckCircle, color: '#48BB78' },
  { value: 'PO Rejected', icon: FaTimes, color: '#FF4444' },
  { value: 'Scheduled', icon: FaCalendarCheck, color: '#9F7AEA' },
  { value: 'Delivered', icon: FaTruck, color: '#38A169' },
];

const getButtonStates = (status) => ({
  assignEnabled: ['Valued', 'Quoted', 'PO Rejected'].includes(status),
  createPOEnabled: status === 'Quoted',
  createPOActive: status === 'Quoted'
});

const ManageDonations = () => {
  const [state, setState] = useState({
    allDonations: [],
    filteredDonations: [],
    distributionCenters: [],
    companies: [],
    user: null,
    userRole: null,
    isLoading: true,
    error: null,
    success: null
  });

  const [filters, setFilters] = useState({
    selectedDC: 'all',
    selectedCompany: 'all',
    statusFilter: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  });

  const [selectedDonation, setSelectedDonation] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [company, setCompany] = useState(null);
  const [distributionCenter, setDistributionCenter] = useState(null);
  const [donationCreator, setDonationCreator] = useState(null);
  const [draftPO, setDraftPO] = useState(null);

  const updateState = useCallback((newState, callback) => {
    setState(prevState => {
      const updatedState = { ...prevState, ...newState };
      if (callback) {
        setTimeout(() => callback(updatedState), 0);
      }
      return updatedState;
    });
  }, []);

  const fetchInitialData = useCallback(async (userId) => {
    try {
      const user = await userService.getById(userId);
      const userRole = await userService.getUserRole(userId);
      let userCompanies = [];
      let allDonations = [];
      let allDCs = [];

      if (userRole === 'SystemAdmin') {
        userCompanies = await companyService.getAll();
      } else {
        // For other roles, assume they only have access to one company
        const companyData = await companyService.getById(user.company);
        userCompanies = [companyData];
      }

      for (const company of userCompanies) {
        const [donationsData, dcsData] = await Promise.all([
          donationService.getByCompany(company.id),
          distributionCenterService.getByCompany(company.id)
        ]);
        allDonations = [...allDonations, ...donationsData];
        allDCs = [...allDCs, ...dcsData];
      }

      updateState({
        allDonations: allDonations,
        filteredDonations: allDonations,
        distributionCenters: allDCs,
        companies: userCompanies,
        user: user,
        userRole: userRole,
        isLoading: false
      });
    } catch (error) {
      console.error('Error fetching initial data:', error);
      updateState({ error: 'Failed to load data. Please try again.', isLoading: false });
    }
  }, [updateState]);

  useEffect(() => {
    const fetchUserAndInitialData = async () => {
      const currentUser = authService.getUserSession();
      if (currentUser) {
        const role = await userService.getUserRole(currentUser.id);
        if (role === 'SystemAdmin') {
          await fetchInitialData(currentUser.id);
        } else {
          updateState({ error: 'Access denied. Only System Admins can manage donations.', isLoading: false });
        }
      } else {
        updateState({ error: 'No user found. Please log in.', isLoading: false });
      }
    };

    fetchUserAndInitialData();
  }, [fetchInitialData, updateState]);

  const applyFilters = useCallback(() => {
    // First filter out completed donations (case-insensitive)
    let filtered = state.allDonations.filter(donation => 
      donation.status.toLowerCase() !== 'completed'
    );
    
    if (filters.selectedCompany !== 'all') {
      filtered = filtered.filter(donation => donation.companyId === filters.selectedCompany);
    }
  
    if (filters.selectedDC !== 'all') {
      filtered = filtered.filter(donation => donation.distributionCenterId === filters.selectedDC);
    }
  
    if (filters.statusFilter !== 'all') {
      filtered = filtered.filter(donation => 
        donation.status.toLowerCase() === filters.statusFilter.toLowerCase()
      );
    }
  
    if (filters.dateRange.start || filters.dateRange.end) {
      filtered = filtered.filter(donation => {
        const donationDate = new Date(donation.createdAt.seconds * 1000);
        return (!filters.dateRange.start || donationDate >= filters.dateRange.start) &&
               (!filters.dateRange.end || donationDate <= filters.dateRange.end);
      });
    }
  
    if (filters.searchTerm) {
      const searchTerm = filters.searchTerm.toLowerCase();
      filtered = filtered.filter(donation =>
        donation.id.toLowerCase().includes(searchTerm) ||
        state.companies.find(c => c.id === donation.companyId)?.name.toLowerCase().includes(searchTerm) ||
        state.distributionCenters.find(dc => dc.id === donation.distributionCenterId)?.name.toLowerCase().includes(searchTerm) ||
        donation.items.some(item => item.description.toLowerCase().includes(searchTerm))
      );
    }
  
    updateState({ filteredDonations: filtered });
  }, [state.allDonations, state.companies, state.distributionCenters, filters, updateState]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handleCompanyChange = (companyId) => {
    setFilters(prev => ({ ...prev, selectedCompany: companyId }));
  };
  
  const handleDCChange = (dcId) => {
    setFilters(prev => ({ ...prev, selectedDC: dcId }));
  };

  const handleStatusFilterChange = (status) => {
    setFilters(prev => ({ ...prev, statusFilter: status }));
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setFilters(prev => ({ ...prev, dateRange: { start, end } }));
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilters(prev => ({ ...prev, searchTerm }));
  };

  const handleManageClick = async (donationId) => {
    const donation = await donationService.getById(donationId);
    setSelectedDonation(donation);

    const company = state.companies.find(c => c.id === donation.companyId);
    setCompany(company);

    const dc = state.distributionCenters.find(dc => dc.id === donation.distributionCenterId);
    setDistributionCenter(dc);

    const user = await userService.getById(donation.createdBy);
    setDonationCreator(user);
  };

  const handleItemAssignment = (item) => {
    setSelectedItem(item);
  };

  const handleAssignmentSave = async (updatedItem) => {
    try {
      const updatedDonation = await donationService.updateItem(selectedDonation.id, updatedItem.itemID, updatedItem);
      setSelectedDonation(updatedDonation);
      updateDonationInState(updatedDonation);
      setSelectedItem(null);
      updateState({ success: 'Item updated successfully.' });
    } catch (error) {
      console.error('Error updating item:', error);
      updateState({ error: 'Failed to update item. Please try again.' });
    }
  };

  const handleFMVSubmit = async (donationId, itemId, fairMarketValue) => {
    if (state.userRole !== 'SystemAdmin') {
      updateState({ error: 'Only System Admins can submit FMV assessments.' });
      return;
    }
    try {
      const updatedDonation = await donationService.submitFMVAssessment(donationId, itemId, fairMarketValue, company, distributionCenter);
      
      const updatedItem = updatedDonation.items.find(item => item.itemID === itemId);
      if (updatedItem) {
        updatedItem.status = 'Valued';
      }
      
      setSelectedDonation(updatedDonation);
      updateDonationInState(updatedDonation);
      updateState({ success: 'FMV Assessment submitted successfully.' });
    } catch (error) {
      console.error('Error submitting FMV Assessment:', error);
      updateState({ error: 'Failed to submit FMV Assessment. Please try again.' });
    }
  };

  const handleCreatePO = (item) => {
    if (state.userRole !== 'SystemAdmin') {
      updateState({ error: 'Only System Admins can create purchase orders.' });
      return;
    }
    setDraftPO({
      itemId: item.itemID,
      description: item.description,
      quantity: item.quantity,
      palletCount: item.palletCount,
      fairMarketValue: item.fairMarketValue || 0,
      shippingQuotePrice: item.shippingQuotePrice || 0,
      date: new Date().toISOString().split('T')[0],
      totalPrice: ((item.fairMarketValue * 0.02) + (item.shippingQuotePrice || 0)).toFixed(2),
      notes: '',
      terms: '',
      shipping: '',
      scheduleForCompletion: '',
      adminUserId: state.user.id,
      adminUserName: state.user.name,
      companyId: company.id,
      companyName: company.name,
      distributionCenterId: distributionCenter.id,
      distributionCenterName: distributionCenter.name,
      originalDonationCreatorId: donationCreator.id,
      originalDonationCreatorName: donationCreator.name,
      status: 'pending',
    });
  };

  const handlePOFieldChange = (field, value) => {
    setDraftPO(prevPO => ({
      ...prevPO,
      [field]: value
    }));
  };

  const handleSubmitPO = async (po) => {
    if (!po.totalPrice || !po.terms || !po.shipping || !po.scheduleForCompletion) {
      updateState({ error: 'Please fill in all required fields.' });
      return;
    }
  
    try {
      // Create the purchase order
      const newPO = await purchaseService.createPurchaseOrder({
        ...po,
        donationId: selectedDonation.id,
        amount: parseFloat(po.totalPrice),
        status: 'pending',
        distributionCenterId: po.distributionCenterId,
        companyId: po.companyId,
      });
  
      // Update the donation item with the PO ID and new status
      const updatedDonation = await donationService.updateItem(
        selectedDonation.id, 
        po.itemId, 
        { 
          purchaseOrderId: newPO.id,
          status: 'PO Sent'  // Set the new status
        }
      );
  
      // Get the user document for the donation creator
      const donationCreatorId = selectedDonation.createdBy;
      const creator = await userService.getById(donationCreatorId);
  
      // Send notification if user has a phone number
      if (creator?.phone) {
        try {
          await messageService.sendPurchaseOrderNotification(
            creator.phone,
            {
              ...po,
              donationId: selectedDonation.id
            }
          );
        } catch (messageError) {
          console.error('Error sending notification:', messageError);
        }
      }
  
      setSelectedDonation(updatedDonation);
      updateDonationInState(updatedDonation);
      setDraftPO(null);
      updateState({ success: 'Purchase Order submitted successfully.' });
    } catch (error) {
      console.error('Error submitting PO:', error);
      updateState({ error: 'Failed to submit purchase order. Please try again.' });
    }
  };

  const updateDonationInState = (updatedDonation) => {
    updateState(prevState => ({
      allDonations: prevState.allDonations.map(donation =>
        donation.id === updatedDonation.id ? updatedDonation : donation
      ),
      filteredDonations: prevState.filteredDonations.map(donation =>
        donation.id === updatedDonation.id ? updatedDonation : donation
      )
    }));
  };

  if (state.isLoading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;
  if (state.userRole !== 'SystemAdmin') return <div className="access-denied">Access denied. Only System Admins can manage donations.</div>;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="manage-donations-container">
          <h1><FaBox /> Manage Donations</h1>
          <FilterSection 
            filters={filters}
            handleDCChange={handleDCChange}
            handleCompanyChange={handleCompanyChange}
            handleStatusFilterChange={handleStatusFilterChange}
            handleDateRangeChange={handleDateRangeChange}
            handleSearch={handleSearch}
            distributionCenters={state.distributionCenters}
            companies={state.companies}
          />
          <DonationsTable 
            donations={state.filteredDonations}
            companies={state.companies}
            distributionCenters={state.distributionCenters}
            handleManageClick={handleManageClick}
          />

          {selectedDonation && (
            <div className="manage-items-section">
              <h2><FaEdit /> Manage Donation: {selectedDonation.id}</h2>
              <StatCards 
                donation={selectedDonation}
                company={company}
                distributionCenter={distributionCenter}
                user={donationCreator}
              />
              <ManageItemsTable 
                donationId={selectedDonation.id}
                items={selectedDonation.items}
                handleItemAssignment={handleItemAssignment}
                handleFMVSubmit={handleFMVSubmit}
                handleCreatePO={handleCreatePO}
              />
            </div>
          )}

          {selectedItem && (
            <AssignDonation
              item={{...selectedItem, donationId: selectedDonation.id}}
              onAssign={handleAssignmentSave}
              onClose={() => setSelectedItem(null)}
            />
          )}
          {draftPO && (
            <DraftPOSection
            draftPO={draftPO}
              handlePOFieldChange={handlePOFieldChange}
              handleSubmitPO={handleSubmitPO}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const FilterSection = ({ 
  filters, 
  handleDCChange, 
  handleCompanyChange,
  handleStatusFilterChange, 
  handleDateRangeChange, 
  handleSearch, 
  distributionCenters,
  companies
}) => (
  <div className="filters">
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select value={filters.selectedCompany} onChange={(e) => handleCompanyChange(e.target.value)}>
        <option value="all">All Companies</option>
        {companies.map(company => (
          <option key={company.id} value={company.id}>{company.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaWarehouse className="icon" />
      <select value={filters.selectedDC} onChange={(e) => handleDCChange(e.target.value)}>
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>{dc.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaFilter className="icon" />
      <select value={filters.statusFilter} onChange={(e) => handleStatusFilterChange(e.target.value)}>
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.value}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <DatePicker
        selectsRange={true}
        startDate={filters.dateRange.start}
        endDate={filters.dateRange.end}
        onChange={handleDateRangeChange}
        isClearable={true}
        placeholderText="Select date range"
      />
    </div>
    <div className="filter-group">
      <FaSearch className="icon" />
      <input
        type="text"
        placeholder="Search donations..."
        value={filters.searchTerm}
        onChange={handleSearch}
      />
    </div>
  </div>
);

const DonationsTable = ({ donations, companies, distributionCenters, handleManageClick }) => (
  <table className="donations-table">
    <thead>
      <tr>
        <th>Donation ID</th>
        <th>Date</th>
        <th>Company</th>
        <th>Distribution Center</th>
        <th>Items</th>
        <th>Total Value</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {donations.map(donation => (
        <tr key={donation.id}>
          <td>{donation.id}</td>
          <td>{new Date(donation.createdAt.seconds * 1000).toLocaleDateString()}</td>
          <td>{companies.find(c => c.id === donation.companyId)?.name || 'N/A'}</td>
          <td>{distributionCenters.find(dc => dc.id === donation.distributionCenterId)?.name || 'N/A'}</td>
          <td>{donation.items.length}</td>
          <td>${donation.totalEstimatedValue.toFixed(2)}</td>
          <td><StatusIndicator status={donation.status} /></td>
          <td>
            <button onClick={() => handleManageClick(donation.id)} className="btn btn-primary">
              <FaEdit /> Manage
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const StatusIndicator = ({ status }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
  
  return (
    <div className="status-indicator" style={{ color: statusOption.color }}>
      <Icon /> {status}
    </div>
  );
};

const StatCards = ({ donation, company, distributionCenter, user }) => (
  <div className="stat-cards">
    <div className="stat-card">
      <FaBuilding className="icon" />
      <h3>Company</h3>
      <p>{company?.name || 'N/A'}</p>
    </div>
    <div className="stat-card">
      <FaWarehouse className="icon" />
      <h3>Distribution Center</h3>
      <p>{distributionCenter?.name || 'N/A'}</p>
    </div>
    <div className="stat-card">
      <FaUser className="icon" />
      <h3>Created By</h3>
      <p>{user?.name || 'N/A'}</p>
    </div>
    <div className="stat-card">
      <FaCalendarAlt className="icon" />
      <h3>Date Created</h3>
      <p>{new Date(donation.createdAt.seconds * 1000).toLocaleDateString()}</p>
    </div>
    <div className="stat-card">
      <FaBox className="icon" />
      <h3>Total Pallet Count</h3>
      <p>{donation.items.reduce((sum, item) => sum + item.palletCount, 0)}</p>
    </div>
    <div className="stat-card">
      <FaMoneyBillWave className="icon" />
      <h3>Total Estimated Value</h3>
      <p>${donation.totalEstimatedValue.toFixed(2)}</p>
    </div>
  </div>
);

const ManageItemsTable = ({ 
  donationId, 
  items, 
  handleItemAssignment,
  handleFMVSubmit,
  handleCreatePO
}) => {
  const [fmvInputs, setFmvInputs] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);
  const [poUrls, setPOUrls] = useState({});

  useEffect(() => {
    const fetchPOUrls = async () => {
      const urls = {};
      for (const item of items) {
        if (item.purchaseOrderId && ['PO Sent', 'PO Rejected'].includes(item.status)) {
          try {
            const po = await purchaseService.getById(item.purchaseOrderId);
            if (po?.pdfUrl) {
              urls[item.itemID] = po.pdfUrl;
            }
          } catch (error) {
            console.error('Error fetching PO:', error);
          }
        }
      }
      setPOUrls(urls);
    };

    fetchPOUrls();
  }, [items]);

  const handleFMVInputChange = (itemID, value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    setFmvInputs(prev => ({...prev, [itemID]: numericValue}));
  };

  const formatCurrency = (value) => {
    if (!value) return '$0.00';
    return `$${parseFloat(value).toFixed(2)}`;
  };

  const handleFMVInputFocus = (itemID) => {
    setFocusedInput(itemID);
  };

  const handleFMVInputBlur = (itemID) => {
    setFocusedInput(null);
    if (fmvInputs[itemID]) {
      setFmvInputs(prev => ({...prev, [itemID]: parseFloat(prev[itemID]).toFixed(2)}));
    }
  };

  const submitFMV = (itemID) => {
    handleFMVSubmit(donationId, itemID, fmvInputs[itemID]);
  };

  const handleKeyPress = (event, itemID) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitFMV(itemID);
    }
  };

  const getButtonStates = (status) => ({
    assignEnabled: ['Valued', 'Quoted', 'PO Rejected'].includes(status),
    createPOEnabled: ['Quoted', 'PO Rejected'].includes(status),
    createPOActive: status === 'Quoted'
  });

  const showPOButton = (status) => ['PO Sent', 'PO Rejected'].includes(status);

  return (
    <table className="manage-items-table">
      <thead>
        <tr>
          <th>Item Description</th>
          <th>Quantity</th>
          <th>Pallet Count</th>
          <th>Estimated Value</th>
          <th>Fair Market Value</th>
          <th>Shipping Price</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => {
          const { assignEnabled, createPOEnabled, createPOActive } = getButtonStates(item.status);
          return (
            <tr key={item.itemID}>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
              <td>{item.palletCount}</td>
              <td>{formatCurrency(item.estimatedValue)}</td>
              <td>
                {!item.fairMarketValue ? (
                  <div className="fmv-input-group">
                    <input 
                      type="text" 
                      value={focusedInput === item.itemID ? 
                        (fmvInputs[item.itemID] || '') : 
                        formatCurrency(fmvInputs[item.itemID] || item.fairMarketValue)}
                      onChange={(e) => handleFMVInputChange(item.itemID, e.target.value)} 
                      onFocus={() => handleFMVInputFocus(item.itemID)}
                      onBlur={() => handleFMVInputBlur(item.itemID)}
                      onKeyPress={(e) => handleKeyPress(e, item.itemID)}
                      className="form-input"
                    />
                    <button 
                      onClick={() => submitFMV(item.itemID)}
                      className="btn btn-primary btn-sm"
                    >
                      Submit FMV
                    </button>
                  </div>
                ) : (
                  <div>{formatCurrency(item.fairMarketValue)}</div>
                )}
                {item.fmvAssessmentUrl && (
                  <a href={item.fmvAssessmentUrl} target="_blank" rel="noopener noreferrer" className="btn btn-secondary btn-sm">
                    <FaFileAlt /> View FMV Assessment
                  </a>
                )}
              </td>
              <td>{formatCurrency(item.shippingQuotePrice)}</td>
              <td>
                <StatusIndicator status={item.status} />
                {showPOButton(item.status) && poUrls[item.itemID] && (
                  <a 
                    href={poUrls[item.itemID]} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="btn btn-secondary btn-sm mt-2"
                  >
                    <FaFileAlt /> View PO
                  </a>
                )}
              </td>
              <td className="actions-cell">
                <div className="actions-container">
                  <button 
                    onClick={() => handleItemAssignment(item)} 
                    className={`btn btn-sm ${
                      assignEnabled ? 'btn-primary' : 'disabled-btn'
                    }`}
                    disabled={!assignEnabled}
                  >
                    Assign to Charity
                  </button>
                  <button 
                    onClick={() => createPOActive && handleCreatePO(item)} 
                    className={`btn btn-sm ${
                      createPOActive ? 'btn-quoted' :
                      !createPOEnabled ? 'disabled-btn' : 'btn-secondary'
                    }`}
                    disabled={!createPOEnabled}
                  >
                    <FaDollarSign /> Create PO
                  </button>
                </div>
                {!assignEnabled && (
                  <div className="tooltip">
                    <span className="tooltiptext">
                      {item.status === 'PO Sent' ? 
                        'Purchase Order has been sent' : 
                        'Item must be Valued, Quoted, or PO Rejected to assign to charity'}
                    </span>
                  </div>
                )}
                {!createPOEnabled && (
                  <div className="tooltip">
                    <span className="tooltiptext">
                      Item must be Quoted or PO Rejected to create a PO
                    </span>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const DraftPOSection = ({ draftPO, handlePOFieldChange, handleSubmitPO }) => (
  <div className="draft-po-section">
    <h3><FaFileAlt /> Draft Purchase Order</h3>
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmitPO(draftPO);
    }}>
      <div className="form-row">
        <div className="form-group">
          <label><FaBox /> Item:</label>
          <input type="text" value={draftPO.description} readOnly className="form-input" />
        </div>
        <div className="form-group">
          <label><FaBox /> Quantity:</label>
          <input type="number" value={draftPO.quantity} readOnly className="form-input" />
        </div>
        <div className="form-group">
          <label><FaBox /> Pallet Count:</label>
          <input type="number" value={draftPO.palletCount} readOnly className="form-input" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label><FaMoneyBillWave /> Fair Market Value:</label>
          <input type="number" value={draftPO.fairMarketValue} readOnly className="form-input" />
        </div>
        <div className="form-group">
          <label><FaShippingFast /> Shipping Quote Price:</label>
          <input type="number" value={draftPO.shippingQuotePrice} readOnly className="form-input" />
        </div>
        <div className="form-group">
          <label><FaCalendarAlt /> Date:</label>
          <input type="date" value={draftPO.date} readOnly className="form-input" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="totalPrice"><FaDollarSign /> Total Price:</label>
          <input
            type="number"
            id="totalPrice"
            value={draftPO.totalPrice}
            onChange={(e) => handlePOFieldChange('totalPrice', e.target.value)}
            step="0.01"
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="status"><FaFilter /> Status:</label>
          <input
            type="text"
            id="status"
            value={draftPO.status}
            readOnly
            className="form-input"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="notes"><FaEdit /> Notes:</label>
        <textarea
          id="notes"
          value={draftPO.notes}
          onChange={(e) => handlePOFieldChange('notes', e.target.value)}
          className="form-textarea"
        />
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="terms"><FaFileAlt /> Terms:</label>
          <input
            type="text"
            id="terms"
            value={draftPO.terms}
            onChange={(e) => handlePOFieldChange('terms', e.target.value)}
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="shipping"><FaShippingFast /> Shipping:</label>
          <input
            type="text"
            id="shipping"
            value={draftPO.shipping}
            onChange={(e) => handlePOFieldChange('shipping', e.target.value)}
            required
            className="form-input"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="scheduleForCompletion"><FaCalendarCheck /> Schedule for Completion:</label>
        <input
          type="date"
          id="scheduleForCompletion"
          value={draftPO.scheduleForCompletion}
          onChange={(e) => handlePOFieldChange('scheduleForCompletion', e.target.value)}
          required
          className="form-input"
        />
      </div>
      <button type="submit" className="btn btn-primary submit-po-btn">
        <FaFileAlt /> Submit Purchase Order
      </button>
    </form>
  </div>
);

export default ManageDonations;