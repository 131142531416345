// src/components/LandingPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css';

function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="landing-container">
      <div className="landing-content">
        <h1 className="landing-title">Welcome to Donating Simplified</h1>
        <p className="landing-description">
          Streamline your donation process and make a difference today.
        </p>
        <div className="landing-buttons">
          <button className="landing-button login" onClick={() => navigate('/login')}>
            Log In
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;