// src/services/logisticsService.js

import { db, storage } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import { purchaseService } from './purchaseService';
import { companyService } from './companyService';
import { distributionCenterService } from './distributionCenterService';
import { charityService } from './charityService';

const COLLECTION_NAME = 'logisticsTasks';
const STORAGE_PATH = 'logisticsTasks';
const BOL_FILE_PREFIX = 'BOL_';

class LogisticsError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'LogisticsError';
    this.code = code;
  }
}

export const logisticsService = {
  async createTask(taskData) {
    try {
      const newTaskRef = doc(collection(db, COLLECTION_NAME));
      const taskWithMetadata = {
        ...taskData,
        id: newTaskRef.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const bolPdfBlob = await this.generateBOL(taskWithMetadata);
      const bolPdfUrl = await this.uploadPDF(bolPdfBlob, newTaskRef.id);
      taskWithMetadata.bolPdfUrl = bolPdfUrl;

      await setDoc(newTaskRef, taskWithMetadata);
      console.log(`Logistics task created successfully: ${newTaskRef.id}`);
      return taskWithMetadata;
    } catch (error) {
      console.error('Error creating logistics task:', error);
      throw new LogisticsError(`Failed to create logistics task: ${error.message}`, 'CREATE_TASK_ERROR');
    }
  },

  async getTasks(filters = {}) {
    try {
      let q = collection(db, COLLECTION_NAME);

      if (filters.distributionCenter) {
        q = query(q, where("distributionCenterId", "==", filters.distributionCenter));
      }
      if (filters.status) {
        q = query(q, where("status", "==", filters.status));
      }
      if (filters.startDate) {
        q = query(q, where("pickupDateTime", ">=", filters.startDate));
      }
      if (filters.endDate) {
        q = query(q, where("deliveryDate", "<=", filters.endDate));
      }
      if (filters.purchaseOrderId) {
        q = query(q, where("purchaseOrderId", "==", filters.purchaseOrderId));
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting logistics tasks:', error);
      throw new LogisticsError(`Failed to get logistics tasks: ${error.message}`, 'GET_TASKS_ERROR');
    }
  },

  async getTaskById(taskId) {
    try {
      const taskDoc = await getDoc(doc(db, COLLECTION_NAME, taskId));
      if (!taskDoc.exists()) {
        console.log(`No logistics task found with ID: ${taskId}`);
        return null;
      }
      return { id: taskDoc.id, ...taskDoc.data() };
    } catch (error) {
      console.error('Error getting logistics task by ID:', error);
      throw new LogisticsError(`Failed to get logistics task by ID: ${error.message}`, 'GET_TASK_ERROR');
    }
  },

  async updateTask(taskId, updateData) {
    try {
      const taskRef = doc(db, COLLECTION_NAME, taskId);
      const taskDoc = await getDoc(taskRef);
      if (!taskDoc.exists()) {
        throw new LogisticsError(`No logistics task found with ID: ${taskId}`, 'TASK_NOT_FOUND');
      }

      const updatedTask = {
        ...taskDoc.data(),
        ...updateData,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(taskRef, updatedTask);
      console.log(`Logistics task updated successfully: ${taskId}`);

      if (updateData.pickupDateTime || updateData.deliveryDate || updateData.status) {
        const newBolPdfBlob = await this.generateBOL(updatedTask);
        const newBolPdfUrl = await this.uploadPDF(newBolPdfBlob, taskId);
        await updateDoc(taskRef, { bolPdfUrl: newBolPdfUrl });
      }

      return updatedTask;
    } catch (error) {
      console.error('Error updating logistics task:', error);
      throw new LogisticsError(`Failed to update logistics task: ${error.message}`, 'UPDATE_TASK_ERROR');
    }
  },

  async deleteTask(taskId) {
    try {
      const taskRef = doc(db, COLLECTION_NAME, taskId);
      const taskDoc = await getDoc(taskRef);
      if (!taskDoc.exists()) {
        throw new LogisticsError(`No logistics task found with ID: ${taskId}`, 'TASK_NOT_FOUND');
      }

      await deleteDoc(taskRef);
      console.log(`Logistics task deleted successfully: ${taskId}`);

      await this.deletePDF(taskId);
    } catch (error) {
      console.error('Error deleting logistics task:', error);
      throw new LogisticsError(`Failed to delete logistics task: ${error.message}`, 'DELETE_TASK_ERROR');
    }
  },

  async generateBOL(task) {
    console.log('Generating BOL for task:', JSON.stringify(task, null, 2));
  
    const pdf = new jsPDF();
    
    try {
      if (!task.purchaseOrderId) {
        throw new Error('Purchase Order ID is missing from the task');
      }
  
      const [purchase, charity, distributionCenter] = await Promise.all([
        purchaseService.getById(task.purchaseOrderId),
        charityService.getById(task.charityId),
        distributionCenterService.getById(task.distributionCenterId)
      ]);
  
      if (!purchase) throw new Error(`Purchase with ID ${task.purchaseOrderId} not found`);
      if (!charity) throw new Error(`Charity with ID ${task.charityId} not found`);
      if (!distributionCenter) throw new Error(`Distribution Center with ID ${task.distributionCenterId} not found`);
  
      const company = await companyService.getById(purchase.companyId);
      if (!company) throw new Error(`Company with ID ${purchase.companyId} not found`);
  
      const formatAddress = (address) => {
        if (typeof address === 'object') {
          return `${address.street}, ${address.city}, ${address.state} ${address.zip}`;
        }
        return address || 'Address not available';
      };
  
      pdf.setFont("helvetica");
      pdf.setFontSize(22);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Bill of Lading', pdf.internal.pageSize.width / 2, 20, { align: 'center' });
  
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Shipper:', 10, 40);
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(company.name, 10, 45);
      pdf.text(formatAddress(distributionCenter.address), 10, 50);
  
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Consignee:', 10, 65);
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(charity.name, 10, 70);
      pdf.text(formatAddress(charity.address), 10, 75);
  
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Pickup Details:', 10, 90);
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(`Date: ${format(new Date(task.pickupDateTime), 'MM/dd/yyyy')}`, 10, 95);
      pdf.text(`Time: ${format(new Date(task.pickupDateTime), 'HH:mm')}`, 10, 100);
  
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Delivery Details:', 10, 115);
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(`Date: ${format(new Date(task.deliveryDate), 'MM/dd/yyyy')}`, 10, 120);
  
      pdf.autoTable({
        startY: 135,
        head: [['Description', 'Quantity', 'Pallet Count', 'Weight', 'Value']],
        body: [
          [
            purchase.description,
            task.palletQuantity.toString(),
            purchase.palletCount ? purchase.palletCount.toString() : 'N/A',
            purchase.weight ? `${purchase.weight} lbs` : 'N/A',
            `$${purchase.fairMarketValue.toFixed(2)}`
          ]
        ],
        theme: 'striped',
        headStyles: { fillColor: [41, 128, 185], textColor: 255 },
        alternateRowStyles: { fillColor: [236, 240, 241] },
      });
  
      let yPos = pdf.autoTable.previous.finalY + 10;
  
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(`Purchase Order ID: ${purchase.id}`, 10, yPos);
      yPos += 5;
      pdf.text(`Logistics Task ID: ${task.id}`, 10, yPos);
      yPos += 5;
      pdf.text(`Status: ${task.status}`, 10, yPos);
      yPos += 10;
  
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Terms:', 10, yPos);
      yPos += 5;
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(purchase.terms || 'No terms specified', 10, yPos, { maxWidth: 180 });
  
      yPos += pdf.getTextDimensions(purchase.terms || '', { maxWidth: 180 }).h + 10;
  
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Notes:', 10, yPos);
      yPos += 5;
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(purchase.notes || 'No notes', 10, yPos, { maxWidth: 180 });
  
      pdf.setFontSize(8);
      pdf.setTextColor(127, 140, 141);
      pdf.text('This is a computer-generated document. No signature is required.', 10, pdf.internal.pageSize.height - 10);
  
      console.log('BOL generated successfully');
      return pdf.output('blob');
    } catch (error) {
      console.error('Error generating BOL:', error);
      throw new LogisticsError(`Failed to generate BOL: ${error.message}`, 'GENERATE_BOL_ERROR');
    }
  },

  async uploadPDF(pdfBlob, taskId) {
    try {
      const storageReference = ref(storage, `${STORAGE_PATH}/${BOL_FILE_PREFIX}${taskId}.pdf`);
      await uploadBytes(storageReference, pdfBlob);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading BOL PDF:', error);
      throw new LogisticsError(`Failed to upload BOL PDF: ${error.message}`, 'UPLOAD_PDF_ERROR');
    }
  },

  async deletePDF(taskId) {
    try {
      const storageReference = ref(storage, `${STORAGE_PATH}/${BOL_FILE_PREFIX}${taskId}.pdf`);
      await deleteObject(storageReference);
      console.log(`BOL PDF deleted successfully for task: ${taskId}`);
    } catch (error) {
      console.error('Error deleting BOL PDF:', error);
      throw new LogisticsError(`Failed to delete BOL PDF: ${error.message}`, 'DELETE_PDF_ERROR');
    }
  },

  async uploadTaxReceipt(file, taskId) {
    try {
      const storageReference = ref(storage, `${STORAGE_PATH}/TaxReceipt_${taskId}.pdf`);
      await uploadBytes(storageReference, file);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading tax receipt:', error);
      throw new LogisticsError(`Failed to upload tax receipt: ${error.message}`, 'UPLOAD_TAX_RECEIPT_ERROR');
    }
  },
};