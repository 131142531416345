// src/services/completedDonationsService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { purchaseService } from './purchaseService';
import { logisticsService } from './logisticsService';

const COLLECTION_NAME = 'completedDonations';

export const completedDonationsService = {
  async createCompletedDonation(purchaseId, taxReceiptUrls, purchaseOrderPdfUrl, palletGroups, distributionCenterId, fmvAssessmentUrl, itemDescription, costBasis) {
    try {
      console.log('Creating completed donation for purchase:', purchaseId);
      const purchase = await purchaseService.getById(purchaseId);
      if (!purchase) {
        throw new Error('Purchase order not found');
      }

      // Fetch all logistics tasks for this purchase
      const logisticsTasks = await logisticsService.getTasks({ purchaseOrderId: purchaseId });

      const newCompletedDonationRef = doc(collection(db, COLLECTION_NAME));
      const completedDonationData = {
        id: newCompletedDonationRef.id,
        purchaseId: purchase.id,
        companyId: purchase.companyId,
        donationId: purchase.donationId,
        itemId: purchase.itemId,
        itemDescription: itemDescription,
        quantity: purchase.quantity,
        palletCount: purchase.palletCount,
        fairMarketValue: purchase.fairMarketValue,
        costBasis: costBasis,
        completionDate: purchase.completionDate,
        taxReceiptUrls: taxReceiptUrls,
        purchaseOrderPdfUrl: purchaseOrderPdfUrl,
        logisticsTaskIds: logisticsTasks.map(task => task.id),
        palletGroups: palletGroups,
        distributionCenterId: distributionCenterId,
        fmvAssessmentUrl: fmvAssessmentUrl,
        createdAt: new Date().toISOString()
      };

      await setDoc(newCompletedDonationRef, completedDonationData);
      console.log(`Completed donation created successfully: ${newCompletedDonationRef.id}`);
      return completedDonationData;
    } catch (error) {
      console.error('Error creating completed donation:', error);
      throw new Error(`Failed to create completed donation: ${error.message}`);
    }
  },

    async getById(completedDonationId) {
        try {
          const completedDonationDoc = await getDoc(doc(db, COLLECTION_NAME, completedDonationId));
          return completedDonationDoc.exists() ? { id: completedDonationDoc.id, ...completedDonationDoc.data() } : null;
        } catch (error) {
          console.error('Error getting completed donation by ID:', error);
          throw error;
        }
    },

    async getAll() {
        try {
          const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
          return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
          console.error('Error getting all completed donations:', error);
          throw error;
        }
    },

    async getByCompany(companyId) {
        try {
          const q = query(collection(db, COLLECTION_NAME), where("companyId", "==", companyId));
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
          console.error(`Error getting completed donations for company ${companyId}:`, error);
          throw error;
        }
    },

    async getByDistributionCenter(distributionCenterId) {
        try {
          const q = query(collection(db, COLLECTION_NAME), where("distributionCenterId", "==", distributionCenterId));
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
          console.error(`Error getting completed donations for distribution center ${distributionCenterId}:`, error);
          throw error;
        }
    },

    async getByDateRange(startDate, endDate) {
        try {
          const q = query(
            collection(db, COLLECTION_NAME), 
            where("completionDate", ">=", startDate),
            where("completionDate", "<=", endDate)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
          console.error(`Error getting completed donations for date range ${startDate} to ${endDate}:`, error);
          throw error;
        }
    },

    async getWithDetails() {
        try {
          const completedDonations = await this.getAll();
          const detailedDonations = await Promise.all(completedDonations.map(async (donation) => {
            const [purchase, logisticsTasks] = await Promise.all([
              purchaseService.getById(donation.purchaseId),
              Promise.all(donation.logisticsTaskIds.map(taskId => logisticsService.getTaskById(taskId)))
            ]);
            return {
              ...donation,
              purchase,
              logisticsTasks
            };
          }));
          return detailedDonations;
        } catch (error) {
          console.error('Error getting completed donations with details:', error);
          throw error;
        }
    }
};