// src/services/messageService.js

class MessageService {
  constructor() {
    // Base64 encode the auth credentials
    this.authToken = btoa(`${process.env.REACT_APP_TWILIO_ACCOUNT_SID}:${process.env.REACT_APP_TWILIO_AUTH_TOKEN}`);
  }

  /**
   * Send a message using Twilio
   * @private
   * @param {string} userPhone - The recipient's phone number in E.164 format
   * @param {string} messageBody - The message content to send
   * @returns {Promise<Object>} Result of the message sending operation
   */
  async sendMessage(userPhone, messageBody) {
    if (!userPhone) {
      throw new Error('User phone number is required');
    }

    try {
      const formData = new URLSearchParams();
      formData.append('To', userPhone);
      formData.append('From', process.env.REACT_APP_TWILIO_PHONE_NUMBER);
      formData.append('Body', messageBody);

      const response = await fetch(
        `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_ACCOUNT_SID}/Messages.json`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${this.authToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData
        }
      );

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send message');
      }

      return {
        success: true,
        messageId: data.sid
      };
    } catch (error) {
      console.error('Error sending message:', error);
      throw new Error('Failed to send message');
    }
  }

  /**
   * Format currency values consistently
   * @private
   * @param {number} amount - The amount to format
   * @returns {string} Formatted currency string
   */
  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  }

  /**
   * Format date consistently
   * @private
   * @param {string|Date} date - The date to format
   * @returns {string} Formatted date string
   */
  formatDate(date) {
    return new Date(date).toLocaleDateString();
  }

  /**
   * Format date and time consistently
   * @private
   * @param {string|Date} datetime - The datetime to format
   * @returns {string} Formatted datetime string
   */
  formatDateTime(datetime) {
    return new Date(datetime).toLocaleString();
  }

  /**
   * Send notification when a new purchase order is created
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} poDetails - Purchase order details
   */
  async sendPurchaseOrderNotification(userPhone, poDetails) {
    const message = this.formatPOMessage(poDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Send notification when a purchase order is approved
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} poDetails - Purchase order details
   */
  async sendPurchaseOrderApprovalNotification(userPhone, poDetails) {
    const message = this.formatPOApprovalMessage(poDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Send notification when a pickup date approval request is created
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Pickup request details
   */
  async sendPickupApprovalRequestNotification(userPhone, details) {
    const message = this.formatPickupApprovalRequestMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Send notification when a pickup request is approved or rejected
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Pickup request decision details
   */
  async sendPickupRequestDecisionNotification(userPhone, details) {
    const message = this.formatPickupRequestDecisionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Send notification when a donation is marked as completed
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Donation completion details
   */
  async sendDonationCompletionNotification(userPhone, details) {
    const message = this.formatDonationCompletionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for new purchase order
   * @private
   */
  formatPOMessage(poDetails) {
    return `
Purchase Order Created for your Donation!

Item: ${poDetails.description}
Quantity: ${poDetails.quantity}
Total Value: ${this.formatCurrency(poDetails.totalPrice)}
Expected Completion: ${this.formatDate(poDetails.scheduleForCompletion)}

Confirm Your Purchase Order at https://app.donatingsimplified.com/purchase-orders
    `.trim();
  }

  /**
   * Format message for purchase order approval
   * @private
   */
  formatPOApprovalMessage(poDetails) {
    return `
Your Purchase Order Has Been Approved!

PO ID: ${poDetails.id}
Company: ${poDetails.companyName}
Amount: ${this.formatCurrency(poDetails.amount)}
Distribution Center: ${poDetails.distributionCenterName}
Approved By: ${poDetails.approverName}
Approval Date: ${this.formatDate(poDetails.approvalTimestamp)}

View the approved purchase order at https://app.donatingsimplified.com/purchase-orders/${poDetails.id}
    `.trim();
  }

  /**
   * Format message for new pickup approval request
   * @private
   */
  formatPickupApprovalRequestMessage(details) {
    return `
Pickup Date Approval Required!

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Proposed Pickup: ${this.formatDateTime(details.pickupDateTime)}
Delivery Date: ${this.formatDate(details.deliveryDate)}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}

Review and approve this pickup request at https://app.donatingsimplified.com/approval-requests
    `.trim();
  }

  /**
   * Format message for pickup request decision
   * @private
   */
  formatPickupRequestDecisionMessage(details) {
    const decisionText = details.status === 'approved' ? 'APPROVED' : 'REJECTED';
    const statusMessage = details.status === 'approved' 
      ? 'Your pickup has been scheduled.'
      : 'Please submit a new pickup request with a different date/time.';
    const decisionBy = `Decision made by: ${details.approverName}`;

    return `
Pickup Request ${decisionText}!

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Requested Pickup: ${this.formatDateTime(details.pickupDateTime)}
Delivery Date: ${this.formatDate(details.deliveryDate)}

${decisionBy}
${statusMessage}

View request details at https://app.donatingsimplified.com/approval-requests
    `.trim();
  }

  /**
   * Format message for completed donation
   * @private
   */
  formatDonationCompletionMessage(details) {
    // Format charities information
    const charitiesList = details.charities
      .map(charity => `- ${charity.name} (${charity.palletCount} pallets)`)
      .join('\n');

    return `
Donation Completed!

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Fair Market Value: ${this.formatCurrency(details.fmvAmount)}
Total Pallets: ${details.totalPallets}
Completion Date: ${this.formatDate(details.completionDate)}

Receiving Charities:
${charitiesList}

View your completed donation in the Reports section:
https://app.donatingsimplified.com/reports

Thank you for your generous donation!
    `.trim();
  }

  /**
   * Validate phone number format (E.164)
   * @param {string} phone - The phone number to validate
   * @returns {boolean} Whether the phone number is valid
   */
  validatePhoneNumber(phone) {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  }
}

export const messageService = new MessageService();