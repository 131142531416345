// src/components/ManageLogistics.js

import React, { useReducer, useCallback, useMemo, useEffect, useState } from 'react';
import { FaSearch, FaCalendarAlt, FaClock, FaTruck, FaCheckCircle, FaExclamationTriangle, FaWarehouse, FaFilter, FaDownload, FaTimesCircle, FaCircle, FaBuilding } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { purchaseService } from '../services/purchaseService';
import { donationService } from '../services/donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import { logisticsService } from '../services/logisticsService';
import { approvalRequestService } from '../services/approvalRequestService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { companyService } from '../services/companyService';
import { charityService } from '../services/charityService';
import '../styles/ManageLogistics.css';
import { completedDonationsService } from '../services/completedDonationsService';
import { messageService } from '../services/messageService';

const initialState = {
  purchases: [],
  logisticsTasks: [],
  user: null,
  userRole: null,
  loading: true,
  error: null,
  filters: {
    status: 'all',
    distributionCenter: 'all',
    company: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  },
  distributionCenters: [],
  companies: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_FILTER':
      return { ...state, filters: { ...state.filters, [action.payload.name]: action.payload.value } };
    case 'RESET_FILTERS':
      return { ...state, filters: initialState.filters };
    default:
      return state;
  }
}

const statusOptions = [
  { value: 'notScheduled', icon: FaCircle, color: '#6c757d', label: 'Not Scheduled' },
  { value: 'pendingApproval', icon: FaExclamationTriangle, color: '#FFA500', label: 'Pending Approval' },
  { value: 'scheduled', icon: FaCalendarAlt, color: '#3498DB', label: 'Scheduled' },
  { value: 'inProgress', icon: FaTruck, color: '#28A745', label: 'In Progress' },
  { value: 'completed', icon: FaCheckCircle, color: '#28A745', label: 'Completed' },
  { value: 'rejected', icon: FaTimesCircle, color: '#E74C3C', label: 'Rejected' },
];

const ManageLogistics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');

      const userRole = await userService.getUserRole(currentUser.id);
      if (userRole !== 'SystemAdmin') {
        throw new Error('Access denied. Only System Admins can manage logistics.');
      }

      const companies = await companyService.getAll();
      const allDCs = await distributionCenterService.getAll();
      const fetchedPurchases = await purchaseService.getApprovedPurchaseOrders();
      const tasks = await logisticsService.getTasks();

      const enrichedPurchases = await Promise.all(fetchedPurchases.map(async (purchase) => {
        try {
          const donation = await donationService.getById(purchase.donationId);
          if (!donation) return null;

          const item = donation.items ? donation.items.find(item => item.itemID === purchase.itemId) : null;
          if (!item) return null;

          const distributionCenter = allDCs.find(dc => dc.id === purchase.distributionCenterId);
          if (!distributionCenter) return null;

          const company = companies.find(c => c.id === purchase.companyId);
          if (!company) return null;

          const palletGroups = item.assignedCharities.map((charity, index) => ({
            palletGroup: index + 1,
            palletQuantity: charity.palletCount,
            charityId: charity.charityId,
            charityName: charity.charityName,
            shippingQuote: item.shippingQuotes[index]
          }));

          return { ...purchase, item, distributionCenter, company, palletGroups };
        } catch (error) {
          console.error(`Error processing purchase ${purchase.id}:`, error);
          return null;
        }
      }));

      const validPurchases = enrichedPurchases.filter(purchase => purchase !== null);

      dispatch({ 
        type: 'SET_DATA', 
        payload: { 
          user: userData,
          userRole,
          distributionCenters: allDCs,
          companies,
          purchases: validPurchases,
          logisticsTasks: tasks 
        } 
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to load logistics data. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSchedule = useCallback(async (purchase, palletGroup, pickupDateTime, deliveryDate) => {
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
  
      const currentUser = authService.getUserSession();
      if (!currentUser || !currentUser.id) {
        throw new Error('No authenticated user found');
      }
  
      const existingTask = state.logisticsTasks.find(t => 
        t.purchaseOrderId === purchase.id && t.palletGroup === palletGroup.palletGroup
      );
  
      const taskData = {
        purchaseOrderId: purchase.id,
        donationId: purchase.donationId,
        itemId: purchase.itemId,
        distributionCenterId: purchase.distributionCenterId,
        charityId: palletGroup.charityId,
        charityName: palletGroup.charityName,
        palletGroup: palletGroup.palletGroup,
        palletQuantity: palletGroup.palletQuantity,
        pickupDateTime: pickupDateTime,
        deliveryDate: deliveryDate,
        status: 'pendingApproval',
        itemDescription: purchase.item?.description || '',
        shippingAddress: purchase.distributionCenter?.address || '',
        requestedByUserId: currentUser.id,
        lastUpdatedBy: currentUser.id,
        lastUpdatedAt: new Date().toISOString()
      };
      
      let newTask;
      if (existingTask) {
        newTask = await logisticsService.updateTask(existingTask.id, {
          ...taskData,
          createdBy: existingTask.createdBy || currentUser.id,
          createdAt: existingTask.createdAt
        });
        toast.success('Task rescheduled successfully');
      } else {
        newTask = await logisticsService.createTask({
          ...taskData,
          createdBy: currentUser.id,
          createdAt: new Date().toISOString()
        });
        toast.success('New task scheduled successfully');
      }
  
      if (!newTask || !newTask.id) {
        throw new Error('Failed to create or update logistics task. Task ID is missing.');
      }
  
      const approvalRequest = await approvalRequestService.createRequest({
        type: 'pickupDateApproval',
        taskId: newTask.id,
        distributionCenterId: purchase.distributionCenterId,
        pickupDateTime,
        deliveryDate,
        requestedByUserId: currentUser.id,
        createdAt: new Date().toISOString(),
        status: 'pending'
      });
  
      try {
        const donation = await donationService.getById(purchase.donationId);
        const creator = await userService.getById(donation.createdBy);
  
        if (creator?.phone) {
          await messageService.sendPickupApprovalRequestNotification(
            creator.phone,
            {
              itemDescription: purchase.item.description,
              distributionCenterName: purchase.distributionCenter.name,
              pickupDateTime,
              deliveryDate,
              charityName: palletGroup.charityName,
              palletQuantity: palletGroup.palletQuantity
            }
          );
        }
      } catch (messageError) {
        console.error('Error sending pickup approval notification:', messageError);
        toast.warn('Task scheduled but notification could not be sent');
      }
  
      await fetchData();
  
    } catch (error) {
      console.error('Error scheduling logistics task:', error);
      dispatch({ type: 'SET_ERROR', payload: `Failed to schedule logistics task: ${error.message}` });
      toast.error('Failed to schedule task. Please try again.');
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  }, [fetchData, state.logisticsTasks]);

  const handleUpdateStatus = useCallback(async (taskId, newStatus) => {
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
      await logisticsService.updateTask(taskId, { status: newStatus });
      await fetchData();
      toast.success(`Task status updated to ${newStatus}`);
    } catch (error) {
      console.error('Error updating task status:', error);
      dispatch({ type: 'SET_ERROR', payload: `Failed to update task status: ${error.message}` });
      toast.error('Failed to update task status. Please try again.');
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  }, [fetchData]);

  const handleCompleteTask = useCallback(async (task, acceptedDate, taxReceiptFile) => {
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
      
      console.log('Starting task completion process for task:', task.id);
  
      const taxReceiptUrl = await logisticsService.uploadTaxReceipt(taxReceiptFile, task.id);
      console.log('Tax receipt uploaded successfully');
  
      const updatedTask = await logisticsService.updateTask(task.id, {
        status: 'completed',
        deliveryDate: acceptedDate,
        acceptedByCharityDate: acceptedDate,
        taxReceiptUrl: taxReceiptUrl
      });
      console.log('Logistics task updated successfully:', updatedTask);
  
      const purchase = await purchaseService.getById(task.purchaseOrderId);
      if (!purchase) {
        throw new Error('Purchase not found');
      }
      console.log('Current purchase data:', purchase);
  
      const donation = await donationService.getById(purchase.donationId);
      if (!donation) {
        throw new Error('Donation not found');
      }
  
      // Fetch distribution center data
      const distributionCenter = await distributionCenterService.getById(task.distributionCenterId);
      if (!distributionCenter) {
        throw new Error('Distribution center not found');
      }
      console.log('Distribution center data:', distributionCenter);
  
      const allTasks = await logisticsService.getTasks({ purchaseOrderId: purchase.id });
      console.log('All related tasks:', allTasks);
  
      const allTasksCompleted = allTasks.every(t => t.status === 'completed');
      console.log('All tasks completed:', allTasksCompleted);
  
      if (allTasksCompleted) {
        console.log('All tasks are completed. Updating purchase order and donation...');
  
        const latestAcceptedDate = Math.max(
          ...allTasks.map(t => new Date(t.acceptedByCharityDate).getTime())
        );
        console.log('Latest accepted date:', new Date(latestAcceptedDate).toISOString());
  
        const [updatedPurchase] = await Promise.all([
          purchaseService.update(purchase.id, {
            status: 'completed',
            completionDate: new Date(latestAcceptedDate).toISOString()
          }),
          donationService.update(donation.id, {
            status: 'completed',
            completionDate: new Date(latestAcceptedDate).toISOString()
          })
        ]);
  
        const taxReceiptUrls = {};
        const palletGroups = allTasks.map(task => ({
          charityId: task.charityId,
          palletGroup: task.palletGroup,
          palletQuantity: task.palletQuantity,
          taxReceiptUrl: task.taxReceiptUrl
        }));
  
        palletGroups.forEach(group => {
          if (group.taxReceiptUrl) {
            taxReceiptUrls[group.palletGroup] = group.taxReceiptUrl;
          }
        });
  
        const item = donation.items.find(i => i.itemID === purchase.itemId);
        if (!item) {
          throw new Error('Item not found in donation');
        }
  
        const completedDonation = await completedDonationsService.createCompletedDonation(
          purchase.id, 
          taxReceiptUrls, 
          updatedPurchase.pdfUrl,
          palletGroups,
          task.distributionCenterId,
          item.fmvAssessmentUrl,
          item.description,
          item.costBasis || 0
        );
        console.log('Completed donation created:', completedDonation);
  
        // Send completion notification to donation creator
        try {
          const creator = await userService.getById(donation.createdBy);
          if (creator?.phone) {
            // Get unique charity IDs from the tasks
            const charityIds = [...new Set(allTasks.map(task => task.charityId))];
            
            // Fetch all charity details in parallel
            const charityPromises = charityIds.map(id => charityService.getById(id));
            const charities = await Promise.all(charityPromises);
            
            // Create charity map with names from charities collection
            const charitiesMap = allTasks.reduce((acc, task) => {
              const charity = charities.find(c => c.id === task.charityId);
              if (charity && task.charityId) {
                if (!acc[task.charityId]) {
                  acc[task.charityId] = {
                    name: charity.name.trim(),
                    palletCount: 0
                  };
                }
                acc[task.charityId].palletCount += parseInt(task.palletQuantity, 10) || 0;
              }
              return acc;
            }, {});
  
            const charitiesArray = Object.values(charitiesMap).filter(
              charity => charity.name && charity.palletCount > 0
            );
  
            if (charitiesArray.length > 0) {
              await messageService.sendDonationCompletionNotification(
                creator.phone,
                {
                  itemDescription: completedDonation.itemDescription || item.description || 'Unnamed Item',
                  distributionCenterName: distributionCenter.name,
                  totalPallets: completedDonation.palletCount,
                  completionDate: completedDonation.completionDate,
                  fmvAmount: completedDonation.fairMarketValue,
                  charities: charitiesArray
                }
              );
            } else {
              console.warn('No valid charity data found for completion notification');
              toast.warn('Donation completed but notification could not be sent due to missing charity data');
            }
          }
        } catch (messageError) {
          console.error('Error sending completion notification:', messageError);
          toast.warn('Donation completed but notification could not be sent');
        }
  
        toast.success('Purchase Order Completed!');
      } else {
        toast.success('Task completed successfully');
      }
  
      await fetchData();
    } catch (error) {
      console.error('Error completing task:', error);
      dispatch({ type: 'SET_ERROR', payload: `Failed to complete task: ${error.message}` });
      toast.error('Failed to complete task. Please try again.');
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  }, [fetchData]);
  
  const filteredPurchases = useMemo(() => {
    return state.purchases.filter(purchase => {
      const searchLower = state.filters.searchTerm.toLowerCase();
      const matchesSearch = 
        purchase.id.toLowerCase().includes(searchLower) ||
        purchase.item.description.toLowerCase().includes(searchLower) ||
        purchase.distributionCenter.name.toLowerCase().includes(searchLower) ||
        purchase.company.name.toLowerCase().includes(searchLower) ||
        purchase.palletGroups.some(group => 
          group.charityName.toLowerCase().includes(searchLower) ||
          group.palletQuantity.toString().includes(searchLower)
        );
      
      const matchesDistributionCenter = state.filters.distributionCenter === 'all' ||
        purchase.distributionCenterId === state.filters.distributionCenter;

      const matchesCompany = state.filters.company === 'all' ||
        purchase.companyId === state.filters.company;
  
      const matchesStatus = state.filters.status === 'all' ||
        purchase.palletGroups.some(group => {
          const task = state.logisticsTasks.find(t => 
            t.purchaseOrderId === purchase.id && t.palletGroup === group.palletGroup
          );
          if (state.filters.status === 'notScheduled') {
            return !task;
          }
          return task && task.status === state.filters.status;
        });
  
      const matchesDateRange = 
        (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
        purchase.palletGroups.some(group => {
          const task = state.logisticsTasks.find(t => 
            t.purchaseOrderId === purchase.id && t.palletGroup === group.palletGroup
          );
          return task && 
            new Date(task.pickupDateTime) >= new Date(state.filters.dateRange.start) &&
            new Date(task.deliveryDate) <= new Date(state.filters.dateRange.end);
        });
  
      return matchesSearch && matchesDistributionCenter && matchesCompany && matchesStatus && matchesDateRange;
    });
  }, [state.purchases, state.logisticsTasks, state.filters]);

  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;
  if (state.userRole !== 'SystemAdmin') return <ErrorMessage message="Access denied. Only System Admins can manage logistics." />;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="manage-logistics-container">
          <div className="logistics-content">
            <h1><FaTruck /> Manage Logistics</h1>
            <FilterBar 
              filters={state.filters}
              distributionCenters={state.distributionCenters}
              companies={state.companies}
              dispatch={dispatch}
              statusOptions={statusOptions}
            />
            <LogisticsTable 
              purchases={filteredPurchases}
              logisticsTasks={state.logisticsTasks}
              onSchedule={handleSchedule}
              onUpdateStatus={handleUpdateStatus}
              onCompleteTask={handleCompleteTask}
              statusOptions={statusOptions}
            />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};

const FilterBar = React.memo(({ filters, distributionCenters, companies, dispatch, statusOptions }) => (
  <div className="filters">
    <div className="filter-group">
      <FaSearch className="icon" />
      <input 
        type="text" 
        placeholder="Search by purchase ID, item, charity, or pallet quantity" 
        value={filters.searchTerm}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'searchTerm', value: e.target.value } })}
      />
    </div>
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select 
        value={filters.company} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'company', value: e.target.value } })}
      >
        <option value="all">All Companies</option>
        {companies.map(company => (
          <option key={company.id} value={company.id}>{company.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaWarehouse className="icon" />
      <select 
        value={filters.distributionCenter} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'distributionCenter', value: e.target.value } })}
      >
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>{dc.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaFilter className="icon" />
      <select 
        value={filters.status} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'status', value: e.target.value } })}
      >
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <input 
        type="date" 
        value={filters.dateRange.start || ''} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { ...filters.dateRange, start: e.target.value } } })}
      />
      <span>to</span>
      <input 
        type="date" 
        value={filters.dateRange.end || ''} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { ...filters.dateRange, end: e.target.value } } })}
      />
    </div>
    <button className="btn btn-reset" onClick={() => dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</button>
  </div>
));

const StatusIndicator = ({ status, statusOptions }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
  
  return (
    <div 
      className={`status-indicator ${status}`}
      data-tooltip-id="tooltip" 
      data-tooltip-content={statusOption.label}
    >
      <Icon /> <span>{statusOption.label}</span>
    </div>
  );
};

const PalletGroupTable = React.memo(({ purchase, logisticsTasks, onSchedule, onCompleteTask, statusOptions }) => {
  const [schedulingGroup, setSchedulingGroup] = useState(null);
  const [completingGroup, setCompletingGroup] = useState(null);
  const [completingTaskId, setCompletingTaskId] = useState(null);

  const handleScheduleClick = (group) => {
    setSchedulingGroup(group);
    setCompletingGroup(null);
  };

  const handleCompleteClick = (group) => {
    setCompletingGroup(group);
    setSchedulingGroup(null);
  };

  const handleScheduleSubmit = (e, group) => {
    e.preventDefault();
    const pickupDate = e.target.pickupDate.value;
    const pickupTime = e.target.pickupTime.value;
    const deliveryDate = e.target.deliveryDate.value;
    const pickupDateTime = `${pickupDate}T${pickupTime}:00`;
    onSchedule(purchase, group, pickupDateTime, deliveryDate);
    setSchedulingGroup(null);
  };

  const handleCompleteSubmit = async (e, group, task) => {
    e.preventDefault();
    setCompletingTaskId(task.id);
    const acceptedDate = e.target.acceptedDate.value;
    const taxReceiptFile = e.target.taxReceipt.files[0];
    try {
      await onCompleteTask(task, acceptedDate, taxReceiptFile);
      setCompletingGroup(null);
    } finally {
      setCompletingTaskId(null);
    }
  };

  return (
    <table className="pallet-group-table">
      <thead>
        <tr>
          <th>Pallet Group</th>
          <th>Assigned Charity</th>
          <th>Pallet Quantity</th>
          <th>Status</th>
          <th>Pickup Date/Time</th>
          <th>Delivery Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {purchase.palletGroups.map(group => {
          const task = logisticsTasks.find(t => 
            t.purchaseOrderId === purchase.id && t.palletGroup === group.palletGroup
          );
          const isCompleting = task && completingTaskId === task.id;

          return (
            <tr key={group.palletGroup}>
              <td>{group.palletGroup}</td>
              <td>{group.charityName}</td>
              <td>{group.palletQuantity}</td>
              <td>
                <StatusIndicator 
                  status={task ? task.status : 'notScheduled'} 
                  statusOptions={statusOptions} 
                />
              </td>
              <td>{task ? new Date(task.pickupDateTime).toLocaleString() : '-'}</td>
              <td>{task ? new Date(task.deliveryDate).toLocaleDateString() : '-'}</td>
              <td>
                <div className="action-buttons">
                  {(!task || task.status === 'rejected') && (
                    <button 
                      className="btn btn-schedule" 
                      onClick={() => handleScheduleClick(group)}
                      data-tooltip-id="tooltip" 
                      data-tooltip-content={task ? "Reschedule rejected task" : "Schedule new task"}
                    >
                      {task ? 'Reschedule' : 'Schedule'}
                    </button>
                  )}
                  {task && (task.status === 'scheduled' || task.status === 'inProgress') && (
                    <button 
                      className="btn btn-complete" 
                      onClick={() => handleCompleteClick(group)}
                      data-tooltip-id="tooltip" 
                      data-tooltip-content="Complete task"
                      disabled={isCompleting}
                    >
                      {isCompleting ? (
                        <span className="loading-button">
                          <div className="spinner"></div>
                          Completing...
                        </span>
                      ) : (
                        'Complete'
                      )}
                    </button>
                  )}
                  {task && task.bolPdfUrl && (
                    <a 
                      href={task.bolPdfUrl} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="btn btn-download"
                      data-tooltip-id="tooltip" 
                      data-tooltip-content="Download Bill of Lading"
                    >
                      <FaDownload /> BOL
                    </a>
                  )}
                </div>
                {schedulingGroup && schedulingGroup.palletGroup === group.palletGroup && (
                  <form onSubmit={(e) => handleScheduleSubmit(e, group)} className="scheduling-form">
                    <div className="form-group">
                      <label htmlFor="pickupDate">Pickup Date:</label>
                      <input type="date" id="pickupDate" name="pickupDate" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="pickupTime">Pickup Time:</label>
                      <input type="time" id="pickupTime" name="pickupTime" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="deliveryDate">Delivery Date:</label>
                      <input type="date" id="deliveryDate" name="deliveryDate" required />
                    </div>
                    <div className="form-actions">
                      <button type="submit" className="btn btn-confirm-schedule">Confirm</button>
                      <button type="button" className="btn btn-cancel-schedule" onClick={() => setSchedulingGroup(null)}>Cancel</button>
                    </div>
                  </form>
                )}
                {completingGroup && completingGroup.palletGroup === group.palletGroup && (
                  <form onSubmit={(e) => handleCompleteSubmit(e, group, task)} className="completing-form">
                    <div className="form-group">
                      <label htmlFor="acceptedDate">Accepted by Charity Date:</label>
                      <input 
                        type="date" 
                        id="acceptedDate" 
                        name="acceptedDate" 
                        required 
                        disabled={isCompleting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="taxReceipt">Tax Receipt PDF:</label>
                      <input 
                        type="file" 
                        id="taxReceipt" 
                        name="taxReceipt" 
                        accept=".pdf" 
                        required 
                        disabled={isCompleting}
                      />
                    </div>
                    <div className="form-actions">
                      <button 
                        type="submit" 
                        className="btn btn-confirm-complete" 
                        disabled={isCompleting}
                      >
                        {isCompleting ? (
                          <span className="loading-button">
                            <div className="spinner"></div>
                            Processing...
                          </span>
                        ) : (
                          'Confirm'
                        )}
                      </button>
                      <button 
                        type="button" 
                        className="btn btn-cancel-complete" 
                        onClick={() => setCompletingGroup(null)}
                        disabled={isCompleting}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

const LogisticsTable = React.memo(({ purchases, logisticsTasks, onSchedule, onUpdateStatus, onCompleteTask, statusOptions }) => {
  const [expandedPurchase, setExpandedPurchase] = useState(null);

  const handleExpand = (purchaseId) => {
    setExpandedPurchase(expandedPurchase === purchaseId ? null : purchaseId);
  };

  return (
    <div className="logistics-table-container">
      <table className="logistics-table">
        <thead>
          <tr>
            <th>Purchase ID</th>
            <th>Company</th>
            <th>Item Description</th>
            <th>Distribution Center</th>
            <th>Total Pallets</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {purchases.map(purchase => (
            <React.Fragment key={purchase.id}>
              <tr className={expandedPurchase === purchase.id ? 'expanded' : ''}>
                <td>{purchase.id}</td>
                <td>{purchase.company?.name || 'N/A'}</td>
                <td>{purchase.item?.description || 'N/A'}</td>
                <td>{purchase.distributionCenter?.name || 'N/A'}</td>
                <td>{purchase.palletGroups.reduce((sum, pg) => sum + pg.palletQuantity, 0)}</td>
                <td>
                  <button className="btn btn-expand" onClick={() => handleExpand(purchase.id)}>
                    {expandedPurchase === purchase.id ? 'Collapse' : 'Expand'}
                  </button>
                </td>
              </tr>
              {expandedPurchase === purchase.id && (
                <tr>
                  <td colSpan="6">
                    <PalletGroupTable 
                      purchase={purchase}
                      logisticsTasks={logisticsTasks}
                      onSchedule={onSchedule}
                      onUpdateStatus={onUpdateStatus}
                      onCompleteTask={onCompleteTask}
                      statusOptions={statusOptions}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ManageLogistics;