// src/components/MyTeam.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyService } from '../services/companyService';
import { distributionCenterService } from '../services/distributionCenterService';
import { userService } from '../services/userService';
import { authService } from '../services/authService';
import { mapService } from '../services/mapService';
import Header from './Header';
import Sidebar from './Sidebar';
import { FaUserPlus, FaWarehouse, FaEdit, FaTrash, FaKey, FaBuilding, FaUsers } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import '../styles/MyTeam.css';

function MyTeam() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('all');
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredDistributionCenters, setFilteredDistributionCenters] = useState([]);
  const [newUser, setNewUser] = useState({ name: '', email: '', phone: '', distributionCenters: [], role: 'User', company: '' });
  const [newUserSelectedCompany, setNewUserSelectedCompany] = useState('');
  const [newDC, setNewDC] = useState({ name: '', street: '', city: '', state: '', zip: '', company: '' });
  const [newCompany, setNewCompany] = useState({ name: '', contactName: '', contactEmail: '', contactPhone: '' });
  const [editingUser, setEditingUser] = useState(null);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [activeSection, setActiveSection] = useState('team');

  const navigate = useNavigate();

  const fetchCompanyData = useCallback(async (companyId = 'all') => {
    try {
      if (isSystemAdmin) {
        const [allCompanies, allDCs, allUsers] = await Promise.all([
          companyService.getAll(),
          distributionCenterService.getAll(),
          userService.getAllUsers()
        ]);

        setCompanies(allCompanies || []);
        setDistributionCenters(allDCs || []);
        setUsers(allUsers || []);

        filterData(companyId, allUsers, allDCs);
      } else {
        const [companyData, dcs, usersData] = await Promise.all([
          companyService.getById(companyId),
          distributionCenterService.getByCompany(companyId),
          userService.getByCompany(companyId)
        ]);

        setCompanies(companyData ? [companyData] : []);
        setDistributionCenters(dcs || []);
        setUsers(usersData || []);
        setFilteredDistributionCenters(dcs || []);
        setFilteredUsers(usersData || []);
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
      setCompanies([]);
      setDistributionCenters([]);
      setUsers([]);
      setFilteredDistributionCenters([]);
      setFilteredUsers([]);
    }
  }, [isSystemAdmin]);

  const filterData = useCallback((companyId, usersList, dcList) => {
    if (companyId === 'all') {
      setFilteredUsers(usersList);
      setFilteredDistributionCenters(dcList);
    } else {
      setFilteredUsers(usersList.filter(user => 
        user.company === companyId || user.role === 'SystemAdmin' || (user.companies && user.companies.includes(companyId))
      ));
      setFilteredDistributionCenters(dcList.filter(dc => dc.company === companyId));
    }
    setSelectedCompany(companyId);
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      const user = authService.getUserSession();
      if (!user) {
        navigate('/login');
        return;
      }
      setCurrentUser(user);
      const userRole = await userService.getUserRole(user.id);
      setIsSystemAdmin(userRole === 'SystemAdmin');
      fetchCompanyData(userRole === 'SystemAdmin' ? 'all' : user.company);
    };
    checkUser();
  }, [navigate, fetchCompanyData]);

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const tempPassword = Math.random().toString(36).slice(-8);
      let userData = {
        name: newUser.name,
        email: newUser.email,
        phone: newUser.phone,
        role: newUser.role,
        password: tempPassword,
      };
      
      let userId;
      if (newUser.role === 'SystemAdmin') {
        const allCompanies = await companyService.getAll();
        const allCompanyIds = allCompanies.map(company => company.id);
        
        userData = {
          ...userData,
          companies: allCompanyIds,
          distributionCenters: []
        };
        
        userId = await userService.createSystemAdmin(userData);
      } else {
        // Determine the company ID
        let companyId;
        if (isSystemAdmin) {
          companyId = selectedCompany === 'all' ? newUser.company : selectedCompany;
          if (!companyId) {
            throw new Error('Company must be selected for new user');
          }
        } else {
          companyId = currentUser.company;
        }
  
        // Fetch all distribution centers for the assigned company
        const companyDCs = await distributionCenterService.getByCompany(companyId);
        const companyDCIds = companyDCs.map(dc => dc.id);
  
        userData = {
          ...userData,
          company: companyId,  // Ensure company ID is added here
          distributionCenters: newUser.role === 'Admin' ? companyDCIds : (newUser.distributionCenters || [])
        };
        
        userId = await userService.create(userData);
      }
  
      // Reset form fields
      setNewUser({ name: '', email: '', phone: '', distributionCenters: [], role: 'User', company: '' });
      setNewUserSelectedCompany('');
  
      // Re-fetch data
      await fetchCompanyData(isSystemAdmin ? selectedCompany : currentUser.company);
  
      alert(`User created successfully. Temporary password: ${tempPassword}`);
    } catch (error) {
      console.error('Error adding new user:', error);
      alert('Failed to add new user. ' + error.message);
    }
  };

  const handleAddDC = async (e) => {
    e.preventDefault();
    try {
      const address = `${newDC.street}, ${newDC.city}, ${newDC.state} ${newDC.zip}`;
      const geocodedLocation = await mapService.geocodeAddress(address).catch(() => null);

      const dcData = {
        name: newDC.name,
        address: {
          street: newDC.street,
          city: newDC.city,
          state: newDC.state,
          zip: newDC.zip,
          latitude: geocodedLocation?.latitude,
          longitude: geocodedLocation?.longitude,
        },
        company: selectedCompany === 'all' ? newDC.company : selectedCompany
      };
      await distributionCenterService.create(dcData);

      setNewDC({ name: '', street: '', city: '', state: '', zip: '', company: '' });
      fetchCompanyData(selectedCompany);
    } catch (error) {
      console.error('Error adding new distribution center:', error);
      alert('Failed to add new distribution center. Please try again.');
    }
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    try {
      const companyData = {
        name: newCompany.name,
        contact: {
          name: newCompany.contactName,
          email: newCompany.contactEmail,
          phone: newCompany.contactPhone
        },
        distributionCenters: []
      };
      await companyService.create(companyData);
      setNewCompany({ name: '', contactName: '', contactEmail: '', contactPhone: '' });
      fetchCompanyData();
      alert('Company added successfully');
    } catch (error) {
      console.error('Error adding new company:', error);
      alert('Failed to add new company. Please try again.');
    }
  };

  const handleInputChange = (e, setStateFunction) => {
    const { name, value, type, options } = e.target;
    if (type === 'select-multiple') {
      const selectedValues = Array.from(options)
        .filter(option => option.selected)
        .map(option => option.value);
      setStateFunction(prevState => ({ ...prevState, [name]: selectedValues }));
    } else {
      setStateFunction(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setNewUser(prevState => ({ ...prevState, company: companyId }));
    setNewUserSelectedCompany(companyId);
    const filteredDCs = distributionCenters.filter(dc => dc.company === companyId);
    setFilteredDistributionCenters(filteredDCs);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await userService.delete(userId);
        fetchCompanyData(selectedCompany);
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user. Please try again.');
      }
    }
  };

  const handleDeleteDC = async (dcId) => {
    if (window.confirm('Are you sure you want to delete this distribution center?')) {
      try {
        await distributionCenterService.delete(dcId);
        fetchCompanyData(selectedCompany);
      } catch (error) {
        console.error('Error deleting distribution center:', error);
        alert('Failed to delete distribution center. Please try again.');
      }
    }
  };

  const handleResetPassword = async (email) => {
    setResetEmail(email);
    setShowResetModal(true);
  };

  const confirmResetPassword = async () => {
    try {
      await authService.resetPassword(resetEmail);
      alert(`Password reset email sent to ${resetEmail}`);
      setShowResetModal(false);
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('Failed to reset password. Please try again.');
    }
  };

  const handleEditUser = (user) => {
    setEditingUser({ ...user });
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
  };

  const handleSaveEdit = async () => {
    try {
      const { email, ...updateData } = editingUser;
      await userService.update(editingUser.id, updateData);
      setEditingUser(null);
      fetchCompanyData(selectedCompany);
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user. Please try again.');
    }
  };

  const renderCompanySelection = () => (
    <div className="company-selection">
      <select 
        value={selectedCompany} 
        onChange={(e) => filterData(e.target.value, users, distributionCenters)}
      >
        <option value="all">All Companies</option>
        {companies.map(company => (
          <option key={company.id} value={company.id}>{company.name}</option>
        ))}
      </select>
    </div>
  );

  const renderTeamMembersTable = () => (
    <div className="table-responsive">
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>
            {isSystemAdmin && <th>Company</th>}
            <th>Distribution Centers</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id}>
              {editingUser && editingUser.id === user.id ? (
                <>
                  <td><input type="text" name="name" value={editingUser.name} onChange={(e) => handleInputChange(e, setEditingUser)} /></td>
                  <td>{user.email}</td>
                  <td><input type="tel" name="phone" value={editingUser.phone} onChange={(e) => handleInputChange(e, setEditingUser)} /></td>
                  <td>
                    <select name="role" value={editingUser.role} onChange={(e) => handleInputChange(e, setEditingUser)}>
                      <option value="User">User</option>
                      <option value="Admin">Admin</option>
                      {isSystemAdmin && <option value="SystemAdmin">System Admin</option>}
                    </select>
                  </td>
                  {isSystemAdmin && (
                    <td>
                      {editingUser.role === 'SystemAdmin' ? 'All Companies' : (
                        <select name="company" value={editingUser.company} onChange={(e) => handleInputChange(e, setEditingUser)}>
                          {companies.map(company => (
                            <option key={company.id} value={company.id}>{company.name}</option>
                          ))}
                        </select>
                      )}
                    </td>
                  )}
                  <td>
                    {editingUser.role === 'SystemAdmin' ? 'All Distribution Centers' : (
                      <select
                        multiple
                        name="distributionCenters"
                        value={editingUser.distributionCenters || []}
                        onChange={(e) => handleInputChange(e, setEditingUser)}
                      >
                        {distributionCenters.map(dc => (
                          <option key={dc.id} value={dc.id}>{dc.name}</option>
                        ))}
                      </select>
                    )}
                  </td>
                  <td>
                    <button className="icon-button save-btn" onClick={handleSaveEdit}>
                      <FaEdit />
                    </button>
                    <button className="icon-button cancel-btn" onClick={handleCancelEdit}>
                      <IoMdClose />
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.role}</td>
                  {isSystemAdmin && <td>{user.role === 'SystemAdmin' ? 'All Companies' : (companies.find(c => c.id === user.company)?.name || 'N/A')}</td>}
                  <td>
                    {user.role === 'SystemAdmin' ? 'All Distribution Centers' : (
                      user.distributionCenters?.map(dcId => 
                        distributionCenters.find(dc => dc.id === dcId)?.name
                      ).join(', ') || 'N/A'
                    )}
                  </td>
                  <td>
                    <button className="icon-button edit-btn" onClick={() => handleEditUser(user)}>
                      <FaEdit />
                    </button>
                    <button className="icon-button delete-btn" onClick={() => handleDeleteUser(user.id)}>
                      <FaTrash />
                    </button>
                    <button className="icon-button reset-btn" onClick={() => handleResetPassword(user.email)}>
                      <FaKey />
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderDistributionCentersTable = () => (
    <div className="table-responsive">
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>State</th>
            {isSystemAdmin && <th>Company</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredDistributionCenters.map(dc => (
            <tr key={dc.id}>
              <td>{dc.name}</td>
              <td>{dc.address?.city || 'N/A'}</td>
              <td>{dc.address?.state || 'N/A'}</td>
              {isSystemAdmin && <td>{companies.find(c => c.id === dc.company)?.name || 'N/A'}</td>}
              <td>
                <button className="icon-button delete-btn" onClick={() => handleDeleteDC(dc.id)}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderCompaniesTable = () => (
    <div className="table-responsive">
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact Name</th>
            <th>Contact Email</th>
            <th>Contact Phone</th>
          </tr>
        </thead>
        <tbody>
          {companies.map(company => (
            <tr key={company.id}>
              <td>{company.name}</td>
              <td>{company.contact?.name}</td>
              <td>{company.contact?.email}</td>
              <td>{company.contact?.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="app-container">
      <Header user={currentUser} />
      <div className="main-content">
        <Sidebar />
        <div className="my-team-container">
        <h1><FaUsers /> My Team </h1>
          {currentUser && <h2>Welcome, {currentUser.name}</h2>}
          {isSystemAdmin && renderCompanySelection()}
          <h3>{selectedCompany === 'all' ? 'All Companies' : companies.find(c => c.id === selectedCompany)?.name || 'N/A'}</h3>
          
          <div className="tabs">
            <button
              className={`tab ${activeSection === 'team' ? 'active' : ''}`}
              onClick={() => setActiveSection('team')}
            >
              Team Members
            </button>
            <button
              className={`tab ${activeSection === 'dc' ? 'active' : ''}`}
              onClick={() => setActiveSection('dc')}
            >
              Distribution Centers
            </button>
            {isSystemAdmin && (
              <button
                className={`tab ${activeSection === 'companies' ? 'active' : ''}`}
                onClick={() => setActiveSection('companies')}
              >
                Companies
              </button>
            )}
          </div>
  
          {activeSection === 'team' && (
            <>
              <h2>Team Members</h2>
              {renderTeamMembersTable()}
              
              <h3>Add New Team Member</h3>
              <form onSubmit={handleAddUser} className="add-form">
                <input type="text" name="name" value={newUser.name} onChange={(e) => handleInputChange(e, setNewUser)} placeholder="Name" required />
                <input type="email" name="email" value={newUser.email} onChange={(e) => handleInputChange(e, setNewUser)} placeholder="Email" required />
                <input type="tel" name="phone" value={newUser.phone} onChange={(e) => handleInputChange(e, setNewUser)} placeholder="Phone" required />
                <select name="role" value={newUser.role} onChange={(e) => handleInputChange(e, setNewUser)} required>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                  {isSystemAdmin && <option value="SystemAdmin">System Admin</option>}
                </select>
                {newUser.role !== 'SystemAdmin' && (
                  <>
                    {isSystemAdmin && selectedCompany === 'all' && (
                      <select 
                        name="company" 
                        value={newUser.company} 
                        onChange={(e) => {
                          handleInputChange(e, setNewUser);
                          handleCompanyChange(e);
                        }} 
                        required
                      >
                        <option value="">Select Company</option>
                        {companies.map(company => (
                          <option key={company.id} value={company.id}>{company.name}</option>
                        ))}
                      </select>
                    )}
                    <select 
                      multiple 
                      name="distributionCenters" 
                      value={newUser.distributionCenters} 
                      onChange={(e) => handleInputChange(e, setNewUser)} 
                      required
                    >
                      {filteredDistributionCenters.map(dc => (
                        <option key={dc.id} value={dc.id}>{dc.name}</option>
                      ))}
                    </select>
                  </>
                )}
                <button type="submit"><FaUserPlus /> Add User</button>
              </form>
            </>
          )}
  
          {activeSection === 'dc' && (
            <>
              <h2>Distribution Centers</h2>
              {renderDistributionCentersTable()}
              
              <h3>Add New Distribution Center</h3>
              <form onSubmit={handleAddDC} className="add-form">
                <input type="text" name="name" value={newDC.name} onChange={(e) => handleInputChange(e, setNewDC)} placeholder="Name" required />
                <input type="text" name="street" value={newDC.street} onChange={(e) => handleInputChange(e, setNewDC)} placeholder="Street" required />
                <input type="text" name="city" value={newDC.city} onChange={(e) => handleInputChange(e, setNewDC)} placeholder="City" required />
                <input type="text" name="state" value={newDC.state} onChange={(e) => handleInputChange(e, setNewDC)} placeholder="State" required />
                <input type="text" name="zip" value={newDC.zip} onChange={(e) => handleInputChange(e, setNewDC)} placeholder="ZIP Code" required />
                {isSystemAdmin && selectedCompany === 'all' && (
                  <select name="company" value={newDC.company} onChange={(e) => handleInputChange(e, setNewDC)} required>
                    <option value="">Select Company</option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))}
                  </select>
                )}
                <button type="submit"><FaWarehouse /> Add Distribution Center</button>
              </form>
            </>
          )}

          {isSystemAdmin && activeSection === 'companies' && (
            <>
              <h2>Companies</h2>
              {renderCompaniesTable()}
              
              <h3>Add New Company</h3>
              <form onSubmit={handleAddCompany} className="add-form">
                <input type="text" name="name" value={newCompany.name} onChange={(e) => handleInputChange(e, setNewCompany)} placeholder="Company Name" required />
                <input type="text" name="contactName" value={newCompany.contactName} onChange={(e) => handleInputChange(e, setNewCompany)} placeholder="Contact Name" required />
                <input type="email" name="contactEmail" value={newCompany.contactEmail} onChange={(e) => handleInputChange(e, setNewCompany)} placeholder="Contact Email" required />
                <input type="tel" name="contactPhone" value={newCompany.contactPhone} onChange={(e) => handleInputChange(e, setNewCompany)} placeholder="Contact Phone" required />
                <button type="submit"><FaBuilding /> Add Company</button>
              </form>
            </>
          )}
        </div>
      </div>
  
      {showResetModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Reset Password</h2>
            <p>Are you sure you want to reset the password for {resetEmail}?</p>
            <button onClick={confirmResetPassword}>Yes, Reset Password</button>
            <button onClick={() => setShowResetModal(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyTeam;